



















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import slugify from 'slugify';
import { ApiResponse } from '@/components/types';
import { CreditAccountDetails } from '@/modules/listOfAssets/types';
import { FETCH_CREDIT_ACCOUNT, GET_CREDIT_ACCOUNT } from '@/modules/listOfAssets/store/creditAccount';
import CardRow from '@/components/CardRow.vue';
import FinanceAccountOpenedDialog from '../components/details/dialogs/FinanceAccountOpenedDialog.vue';
import FinanceAccountStartDepositDialog from '../components/details/dialogs/FinanceAccountStartDepositDialog.vue';
import FinanceAccountCloseDialog from '@/modules/listOfAssets/components/details/dialogs/FinanceAccountCloseDialog.vue';
import TransactionExportDialog from '@/modules/listOfAssets/components/TransactionExportDialog.vue';
import TransactionCard from '@/modules/listOfAssets/components/details/cards/TransactionCard.vue';
import CreditAccountDialog from '../components/details/dialogs/CreditAccountDialog.vue';
import VoucherExportDialog from '@/modules/listOfAssets/components/VoucherExportDialog.vue';

const CreditAccount = namespace('listOfAssets/creditAccount');

@Component({
  components: {
    VoucherExportDialog,
    CreditAccountDialog,
    TransactionCard,
    TransactionExportDialog,
    FinanceAccountCloseDialog,
    FinanceAccountStartDepositDialog,
    FinanceAccountOpenedDialog,
    CardRow
  }
})
export default class CreditAccountDetailsView extends Vue {
  @CreditAccount.Action(FETCH_CREDIT_ACCOUNT) fetchDetails!: (account: string) => Promise<ApiResponse>;
  @CreditAccount.Getter(GET_CREDIT_ACCOUNT) getDetails!: (account: string) => CreditAccountDetails | undefined;

  get account(): CreditAccountDetails | undefined {
    return this.getDetails(this.$route.params['id']);
  }

  get backURL() {
    if (this.$route.query['referrer']) {
      return this.$route.query['referrer'];
    }

    if (!this.account) return '';

    return { name: 'list-of-assets-details', params: { id: this.account.listOfAssetsId } };
  }

  slugify(string: string) {
    return slugify(string);
  }

  @Watch('$route.params.id', { immediate: true })
  onIdChange() {
    return this.fetchDetails(this.$route.params['id']);
  }
}
