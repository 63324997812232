
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { CreditAccountType } from '../types';
import { creditAccount } from '../statics';

@Component({
  inheritAttrs: false
})
export default class CreditAccountTypeSelect extends Vue {
  @Prop({ type: [Array, String] }) value!: null | CreditAccountType | CreditAccountType[];
  @Prop({ type: Boolean, default: false }) multiple!: boolean;

  get list(): Array<{ value: CreditAccountType, text: TranslateResult }> {
    return creditAccount.map((type) => ({ value: type, text: this.$i18n.t(`financeAccount.type.${type}`) }));
  }

  created() {
    this.$emit('input', this.value);
  }
}
