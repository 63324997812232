




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_CREDIT_ACCOUNT } from '@/modules/listOfAssets/store/creditAccount';
import CreditAccountConvertDialog from '../dialogs/CreditAccountConvertDialog.vue';
import IncomeProducingSelect from '../../IncomeProducingSelect.vue';
import CreditAccountTypeSelect from '../../CreditAccountTypeSelect.vue';
import { ChangeCreditAccount, CreditAccountDetails } from '@/modules/listOfAssets/types';
import { ApiResponse } from '@/components/types';

const CreditAccount = namespace('listOfAssets/creditAccount');

@Component({
  components: {
    CreditAccountTypeSelect,
    CreditAccountConvertDialog,
    IncomeProducingSelect
  }
})
export default class CreditAccountDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: CreditAccountDetails;
  @CreditAccount.Action(CHANGE_CREDIT_ACCOUNT) changeCreditAccount!: (value: ChangeCreditAccount) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  typeDialog: boolean = false;

  value: ChangeCreditAccount = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    credit_institute: this.account.creditInstitute,
    credit_number: this.account.creditNumber,
    income_producing: this.account.incomeProducing,
    note: this.account.note
  };

  openTypeDialog() {
    this.typeDialog = true;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        finance_account_id: this.account.id,
        list_of_assets_id: this.account.listOfAssetsId,
        credit_institute: this.account.creditInstitute,
        credit_number: this.account.creditNumber,
        income_producing: this.account.incomeProducing,
        note: this.account.note
      };

      return;
    }

    setTimeout(() => {
      this.error = null;

      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('typeDialog')
  watchTypeDialogClosed(dialog: boolean) {
    if (dialog) return;

    this.dialog = false;
  }

  save() {
    this.error = null;

    return this.changeCreditAccount(this.value);
  }
}
