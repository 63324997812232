




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CONVERT_CREDIT_ACCOUNT } from '../../../store/creditAccount';
import { ConvertCreditAccount, CreditAccountDetails } from '../../../types';
import { ApiResponse } from '@/components/types';
import CreditAccountTypeSelect from '../../CreditAccountTypeSelect.vue';

const CreditAccount = namespace('listOfAssets/creditAccount');

@Component({
  components: {
    CreditAccountTypeSelect
  }
})
export default class CreditAccountConvertDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: CreditAccountDetails;
  @Prop({ type: Boolean, required: true }) dialog!: boolean;

  @CreditAccount.Action(CONVERT_CREDIT_ACCOUNT) convertCreditAccount!: (convert: ConvertCreditAccount) => Promise<ApiResponse>;

  error: any = null;

  value: ConvertCreditAccount = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    type: this.account.type
  };

  get iDialog() {
    return this.dialog;
  }

  set iDialog(dialog: boolean) {
    this.$emit('update:dialog', dialog);
  }

  @Watch('iDialog')
  onDialogChange() {
    this.value = {
      finance_account_id: this.account.id,
      list_of_assets_id: this.account.listOfAssetsId,
      type: this.account.type
    };
  }

  save() {
    this.error = null;

    return this.convertCreditAccount(this.value);
  }
}
